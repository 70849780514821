<template>
  <fw-layout full back-to="-1" main-content-position="center" main-content-max-width-on-full="md" :notfound="!task">
    <template #header-nav>
      <fw-heading>Suporte <span class="font-medium">/ Pedidos de ajuda</span></fw-heading>
    </template>

    <template #main-content>
      <div class="relative h-full">
        <PanelTask
          v-if="taskToken"
          :task="task"
          :queue="queue"
          :space="space"
          :loading="loading"
          :users="users"
          :messages="messages"
          @set-rating="setRating"
          @add-message="addMessage"
          @page-changed="messagesPageChanged"
        />
        <fw-loading-blur-panel v-if="loading.task" />
      </div>
    </template>

    <template #modals>
      <ModalFeedbackMessage
        :active.sync="isRatingModalOpen"
        :rating="rating"
        :task-id="taskToken"
        @close="closeRatingModal"
      ></ModalFeedbackMessage>
    </template>
  </fw-layout>
</template>

<script>
import PanelTask from '@/fw-modules/fw-core-vue/tasks/components/panels/PanelTask'
import ModalFeedbackMessage from '@/fw-modules/fw-core-vue/tasks/components/modals/ModalFeedbackMessage'

import ServiceTasks from '@/fw-modules/fw-core-vue/tasks/services/ServiceTasks'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelTask,
    ModalFeedbackMessage
  },

  data() {
    return {
      subjects: [],
      tasks: [],
      loading: {
        task: false,
        messages: false
      },
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25
      },
      task: {},
      queue: {},
      space: {},
      messages: [],
      users: {},
      rating: null,
      isRatingModalOpen: false,
      isCreateTaskModalActive: false
    }
  },

  computed: {
    taskToken() {
      return this.$route.params.token
    }
  },

  watch: {
    taskToken(newVal) {
      if (newVal) {
        this.getTask()
      }
    }
  },

  mounted() {
    console.log('this.taskToken', this.taskToken)
    this.getTask()
  },

  methods: {
    async getTask() {
      this.loading.task = true

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await ServiceTasks.getUserSupportTask(this.taskToken)
          // TODO: get context details
          console.log('getUserSupportTask', response)
          this.task = response.task
          this.space = response.space
          this.queue = response.queue

          if (response.task.participants && response.users) {
            this.$set(
              this.task,
              'participants',
              response.task.participants.map(p => response.users[p])
            )
          }
          this.getTaskMessages()
        },
        null,
        async error => {
          const errorKey = utils.errors(error).getKey()
          console.error('errorKey', errorKey, errorKey == 'ExpiredToken')
          if (errorKey == 'ExpiredToken') {
            this.$buefy.dialog.alert({
              title: 'Token expirado',
              message: `<div class="mb-3">O token de suporte expirou. Por favor faça login.</div>`,
              type: 'is-danger',
              onConfirm: () => {
                this.$router.push({ name: 'login' })
              }
            })
          } else {
            utils.errorDialogAlert(this, error)
          }
        },
        false
      )

      this.loading.task = false
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.messages.pagination.page = page
      this.getTaskMessages()
    },

    async getTaskMessages() {
      this.loading.messages = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceTasks.getUserSupportTaskMessages(this.taskToken, {
          limit: this.pagination.limit,
          page: this.pagination.page
        })
        console.log('getUserSupportTaskMessages', response)
        this.messages = response.messages
        this.users = { ...this.users, ...response.users }
        this.pagination = {
          page: response.pagination?.current_page,
          totalResults: response.pagination?.total_items,
          totalPages: response.pagination?.total_pages,
          limit: response.pagination?.active_limit
        }
      })

      this.loading.messages = false
    },

    async addMessage(newMessage) {
      this.loading.messages = true

      console.log('add newMessage :>> ', newMessage)

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await ServiceTasks.addUserSupportTaskMessages(this.taskToken, newMessage)
          this.task = response.task
          this.messages.unshift(response.message)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )

      this.loading.messages = false
    },

    async generateToken() {
      await utils.tryAndCatch(
        this,
        async () => {
          await ServiceTasks.generateUserSupportTaskToken(this.taskToken)
          this.$buefy.toast.open({
            message: 'O novo código de acesso foi gerado.',
            type: 'is-primary'
          })
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )
    },

    async setRating(score) {
      console.log('add score :>> ', score)

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await ServiceTasks.addUserSupportTaskRating(this.taskToken, { score: score })
          if (this.task.ratings) this.task.ratings.push(response)
          else this.task.ratings = [response]
          this.openRatingModal(response)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )

      this.loading.task = false
    },

    openRatingModal(ratingData) {
      this.rating = ratingData
      this.isRatingModalOpen = true
    },

    closeRatingModal() {
      this.isRatingModalOpen = false
      this.rating = null
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "support": "Suporte",
    "add": "Criar pedido",
    "errorOccurred": "Ocorreu um erro a guardar o pedido",
    "procedure": {
      "label": "Procedimento",
      "placeholder": "Pesquise e selecione o procedimento"
    }
  },
  "en": {
    "support": "Support",
    "add": "Add issue",
    "errorOccurred": "An error occurred while saving the ticket",
    "procedure": {
      "label": "Procedure",
      "placeholder": "Search and choose the related procedure"
    }
  }
}
</i18n>
